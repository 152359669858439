import React from 'react'
// import CirclePartnersTemplate from './partnersComponent/CirclePartnersTemplate'
import { PartnerTemplate } from './PartnerTemplate';
import tilet from '../../Assets/partners/TiletLogo.png';
import blue from '../../Assets/partners/Logo Transparent 3.png';
import summer from '../../Assets/partners/summer.png'


const Partners = () => {
  return (
    <div id='Partners'>
      {/* 
      <div className='flex justify-center flex-wrap'>
        <CirclePartnersTemplate img={tilet} name={'Tilet Tech'}/>
        <CirclePartnersTemplate img={blue} name={'Blue health Ethiopia'}/>
      </div> */}
    <div className='w-full h-full py-16 bg-white'>
      <h1 className='text-center md:text-4xl text-2xl font-bold pt-2 mb-10 text-blueblack'>Partners</h1>
      <div className='flex  justify-center items-center'>
        <PartnerTemplate image={tilet} bidscreen='32' size='32' title={'Tilet Tech'} />
        <PartnerTemplate image={blue} link={'https://bluehealthethiopia.com/'} bigscreen='56' size='40' title={'Blue Health Ethiopia'} />
        <PartnerTemplate image={summer} bigscreen='32' size='32' title={'summer'}  />
      </div>
    </div>
    </div>
  )
}

export default Partners