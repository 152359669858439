import React from 'react'
import {motion} from 'framer-motion'

const CircleTemplate = ({ Text = "Sample Text", right,body,icon }) => {


  return right ? (
    <motion.div className='mt-5 flex md:justify-start justify-center items-center'>
    <motion.div className='rotate-90 md:rotate-0 md:h-60 h-[21rem] w-[23rem]  bg-white md:w-[70%] flex justify-center items-center md:rounded-r-full md:flex-row flex-row-reverse mt-10'>
      <p className='m-2 flex-1 md:rotate-0 -rotate-90 text-xl font-light leading-snug w-[45rem] text-center'>{body} </p>
      <div className={`md:h-72 md:w-72 w-52 h-52 -ml-24 md:m-0  md:rotate-0 -rotate-90 flex justify-center items-center flex-col border-8 border-primary_lighter bg-secondary p-12 rounded-full z-20`}>
        <img src={icon} className='invert w-16 m-5' alt="" />
        <h1 className='text-center font-bold text-white md:text-3xl text-xl'>
          {Text}
        </h1>
      </div>
    </motion.div>
    </motion.div>
  ) :
  <motion.div className='mt-5 flex md:justify-end justify-center items-center' 

  >
    <motion.div className='rotate-90 md:rotate-0 md:h-60 h-[21rem] w-[23rem]  bg-white md:w-[70%] flex justify-center items-center md:rounded-l-full  my-24 '
    >
      <div className={`md:h-72 md:w-72 w-52 h-52 -ml-24 md:m-0  md:rotate-0 -rotate-90 flex justify-center items-center flex-col border-8 border-primary_lighter bg-primary p-12 rounded-full z-20`}>
        <img src={icon} className='invert w-16 m-5' alt="" />
        <h1 className=' text-center font-bold text-white md:text-3xl text-2xl'>
        {Text}
        </h1>
      </div>
      <p className='m-2 flex-1 md:rotate-0 -rotate-90 text-xl font-light leading-snug w-[45rem] text-center'>{body}</p>
    </motion.div>
  </motion.div>
}


export default CircleTemplate