import React from 'react'
import { Link } from 'react-scroll'
import logo from '../../Assets/MamasLogo.png'
import logowhite from '../../Assets/logoWhite.png'
import { motion } from "framer-motion"


const DesktopNavigation = ({sticky}) => {
  const container = {
    hidden: { y:-100,opacity: 0 },
    visible: {
      y:0,
      opacity: 1,
      transition: {
      duration:2,
      type: "spring",
      stiffness: 60,
      damping: 10
        }
    }
};
  return (
    <motion.div className={!sticky?'bg-transparent hidden px-8 md:flex backdrop-blur-md shadow-sm w-full  justify-between absolute text-white':
    'fixed bg-white hidden md:flex w-full justify-between px-8 z-30 transition-all shadow-sm'}
    variants={container}
    initial="hidden"
    whileInView="visible">
    <Link to='Home'><motion.img src={sticky?logo:logowhite}alt="" className='w-24 m-2'/></Link>
    <motion.div className='flex items-center px-5 transition-all text-sm'>
        <Link spy={true}
          smooth={true}
          offset={-100}
          duration={1000} to='Home'  
          className='nav'
          >Home</Link>
        <Link spy={true}
          smooth={true}
          offset={-150}
          duration={1000} 
          to='About' 
          className='nav' >About us</Link>
        <Link spy={true}
          smooth={true}
          offset={-100}
          duration={1000} 
          to='Services' 
          className='nav' >Services</Link>
        <Link spy={true}
          smooth={true}
          offset={-100}
          duration={1000}  
          to='Partners' 
          className='nav' >Partners</Link>
        <Link spy={true}
          smooth={true}
          offset={-150}
          duration={1000}  
          to='Contact' 
          className='nav' >Contact us</Link>
    </motion.div>
    </motion.div>
  )
}

export default DesktopNavigation