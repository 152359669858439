import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";

const ModalTemplate = ({ isOpen, onClose, children }) => {
    const formVariants = {
        open: {
          y:0,
          opacity: 1,
          transition: {
            duration:1,
            type:'spring',
            stiffness: 100,
            damping: 10
          },
        },
        closed: {
          y:-100,
          opacity: 0,
          transition: {
            duration: 1,
            type:'spring',
          },
        },
      };

    const [isClosing, setIsClosing] = useState(false)

    useEffect(() => {
        setIsClosing(false)
    }, [isOpen])


    const handleClose = () => {
        setIsClosing(true)
        setTimeout(() => {
            setIsClosing(false)
            onClose()
        }, 300);
    }
    return (
        <>
        <AnimatePresence>
            {isOpen && <motion.div
                className={`fixed z-40 top-0 left-0 w-full h-full bg-bgtransparent opacity-100 flex justify-center items-center transition-opacity ${isClosing ? 'opacity-0' : 'opacity-100'}`}
                onClick={handleClose}
                >
                <motion.div className={`bg-slate-100 p-4 rounded-lg shadow-lg transform transition-transform ${isClosing ? 'scale-90 opacity-0' : 'scale-100 opacity-100'}`}
                    onClick={(e) => { e.stopPropagation() }}
                    variants={formVariants}
                    initial="closed"
                    animate="open"
                    exit='closed'
                    >
                    {children}
                </motion.div>
            </motion.div>}
            </AnimatePresence>
        </>
    )
}

export default ModalTemplate