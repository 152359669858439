import AboutUs from "./Components/About us/AboutUs";
import Home from "./Components/Home/Home";
import Loader from "./Components/Loader";
import DesktopNavigation from "./Components/Navigation/DesktopNavigation";
import ProgressBar from 'react-progressbar-on-scroll'
import Service from "./Components/Service/Service";
import useSticky from "./Components/useSticky";
import Footer from "./Components/Footer/Footer";
import Partners from "./Components/Partners/Partners";
import MobileNavigation from "./Components/Navigation/MobileNavigation";
import { useEffect } from "react";
import { useState } from "react";

function App() {
  const [isLoading,setLoading]=useState(true);
  const isSticky=useSticky(500)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])
  return (
   <>
   {isLoading?
    <Loader/>:
    <>
    <ProgressBar color="#ea8686" height={8}/>
    <DesktopNavigation sticky={isSticky}/>
    <MobileNavigation sticky={isSticky}/>
    <Home/>
    <AboutUs/>
    <Service/>
    <Partners/>
    <Footer/>
    </>}
   </>
  );
}

export default App;