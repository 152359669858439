import React from 'react'
import logo from '../Assets/MamasLogo.png'

const Loader = () => {
  return (
    <div className='h-screen flex justify-center items-center'>
    <img className='w-40 animate-pulse' src={logo} alt="" />
    </div>
  )
}
export default Loader