import React from 'react'
import Texts from '../../Values/Texts'
import pinkbglogo from './../../Assets/logoPinkBG.svg'
import { motion } from 'framer-motion'
import { useInView } from "react-intersection-observer";

const AboutUs = () => {
  const [ref, inView] = useInView({
    threshold: 0.5, // trigger when the component is 50% visible
    triggerOnce: true, // only trigger once
  });
  const container={
    hidden:{
      opacity:0,
      transition: {
        when: "afterChildren",
      },
    },
    visible:{
      opacity:1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
        duration: 1,
      },
    }
  }
  const img={
    hidden:{
      x:-100,
      opacity:0
    },
    visible:{
      x:0,
      opacity:1,
      transition:{
        duration:1,
      }
  }
}
const text={
hidden:{
  opacity:0,
  scale:0
},
visible:{
  opacity:1,
  scale:1,
  transition:{
    duration:1,
    type: "spring",
    stiffness: 50,
    damping: 20
    }
}
}
  return (
    <div className='h-full'  id='About'>
    <motion.div className='bg-primary_lighter mb-10 md:mb-28' 
    ref={ref}
    variants={container}
    initial="hidden"
    animate={inView ? "visible":"hidden"}
    >
    <h1 className='text-center md:text-4xl text-2xl font-bold m-5 md:ml-72'>About Us</h1>
     <div className='bg-white w-full md:h-48 md:flex'>
     <motion.img className="h-48 float-left ml-5 mr-5 top-[-35px] rounded-2xl border-8 border-primary_lighter md:relative md:ml-14 md:h-80 md:w-auto md:top-[-60px]" src={pinkbglogo} alt="" 
     variants={img}
     />
     <div className="lg:text-center p-3 lg:p-10 item-body">
     <motion.p className='lg:text-xl static font-light'
     variants={text}
     >
      {Texts.About}
    </motion.p>
     </div>
    </div> 
    </motion.div>
  </div>
  )
}

export default AboutUs
