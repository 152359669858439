import React from 'react'
import { Link } from 'react-scroll'
import logo from '../../Assets/MamasLogo.png'
import logowhite from '../../Assets/logoWhite.png'
import {BiMenuAltRight} from 'react-icons/bi'
import {IoMdClose} from 'react-icons/io'
import { useState } from 'react'

const MobileNavigation = ({sticky}) => {
  const [isOpen,setIsOpen]=useState(true)
  
  const handleOpen=()=>{
    setIsOpen(!isOpen)
  }
  console.log(isOpen)
  return (
    <>
    <div className={!sticky?'bg-bgtransparent px-4 flex md:hidden items-center shadow-sm w-full h-fit z-30 justify-between fixed text-white':
    'fixed bg-white px-4 flex md:hidden w-full justify-between items-center z-30 transition-all shadow-sm text-black'}>
    <Link to='Home'><img src={sticky?logo:logowhite}alt="" className='md:w-24 w-20  m-2' /></Link>
    <BiMenuAltRight onClick={handleOpen} className='w-10 h-10 hover:text-primary transition-all cursor-pointer ' />
    <div className={`fixed top-0 ${isOpen?'right-0 transition-all':'right-80 transition-all'} h-screen w-full ${!sticky?'bg-bgtransparent backdrop-blur-xl text-white':'bg-white text-black'} z-10 shadow-lg transition-all duration-300 transform translate-x-full`}>
    <IoMdClose onClick={handleOpen} className='w-10 h-10 mx-3 mt-8 -mb-5 flex items-center justify-center hover:text-primary transition-all cursor-pointer'/>
    <div className='flex flex-col items-start font-semibold px-5 justify-center h-full transition-all text-lg space-y-6'>
        <Link spy={true}
        onClick={handleOpen}
          smooth={true}
          offset={-100}
          duration={1000} to='Home'  
          className='cursor-pointer hover:text-primary' >Home</Link>
        <Link spy={true}
        onClick={handleOpen}
          smooth={true}
          offset={-150}
          duration={1000} 
          to='About' 
          className='cursor-pointer hover:text-primary' >About us</Link>
        <Link spy={true}
        onClick={handleOpen}
          smooth={true}
          offset={-100}
          duration={1000} 
          to='Services' 
          className='cursor-pointer hover:text-primary' >Services</Link>
        <Link spy={true}
        onClick={handleOpen}
          smooth={true}
          offset={-100}
          duration={1000}  
          to='Partners' 
          className='cursor-pointer hover:text-primary' >Partners</Link>
        <Link spy={true}
        onClick={handleOpen}
          smooth={true}
          offset={-150}
          duration={1000}  
          to='Contact' 
          className='cursor-pointer hover:text-primary' >Contact us</Link>
    </div>
    
   </div>
</div>
    </>
  )
}

export default MobileNavigation