import ModalTemplate from '../ModalTemplate'
import img from '../../Assets/emailus.svg'
import emailjs from '@emailjs/browser';
import {AiFillCheckCircle} from 'react-icons/ai'
import {MdError} from 'react-icons/md'
import { FaRegEnvelope,FaPaperclip,FaRegComment,FaArrowLeft} from 'react-icons/fa'
import { AiOutlineSend} from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRef } from 'react';
import { motion } from 'framer-motion'

const Email = ({isOpen,isClosed}) => {
  const from =useRef();
  const sendEmail=(e)=>{
    e.preventDefault();
  emailjs.sendForm('service_cmht9vu', 'template_vl51opo',from.current,"8zLcpJNNMP6eldGzn")
    .then((result) =>{
       console.log(result.text);
       toast("Email is sent Successfully!", {
        position: "bottom-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
icon: AiFillCheckCircle,
progress: undefined,
theme: "dark",
        });
    }, (error) =>{
       console.log(error.text);
       toast.error('Email was not sent!', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        icon:MdError,
        theme: "dark",
        });
    });
  }
  return (
    <div>
        <ModalTemplate isOpen={isOpen} onClose={isClosed}>
        <motion.div className='flex w-full flex-col md:flex-row'
        >
          <FaArrowLeft onClick={isClosed} className='absolute cursor-pointer text-secondary hover:text-primary transition-all'/>
          <div className='flex-1 flex justify-center items-center flex-col'>
            <img src={img} alt="" className='md:w-64 w-32 m-5 rounded-md'/>
            <div className='flex justify-between'>
            <h1 className='text-secondary font-bold tracking-widest text-2xl'>EMAIL </h1>
            <h1 className='text-primary font-light ml-1 text-2xl '>US</h1>
            </div>
          </div>
          <form className='flex-1 flex flex-col  justify-end w-96 md:96 shadow-lg p-8 bg-white' ref={from} onSubmit={sendEmail}>
            <div className='relative w-full'>
              <input type="email" maxLength={30} className='w-full text-sm p-3 border-b-2 border-gray-400 text-black outline-none focus:border-primary transition-all' placeholder='Email Address' name='from_name' required/>
              <div className='absolute inset-y-0 right-0 flex items-center px-2 text-primary_medium'>
                <FaRegEnvelope/>
              </div>
            </div>

            <div className='relative w-full'>
              <input type="text" maxLength={30} className='w-full p-3 text-sm border-b-2 border-gray-400 outline-none text-black focus:border-primary transition-all' placeholder='Subject' name='to_name' required/>
              <div className='absolute inset-y-0 right-0 flex items-center px-2 text-primary_medium'>
                <FaPaperclip/>
              </div>
            </div>

            <div className='relative w-full'>
              <textarea type="text" rows={6} className='w-full h-full text-sm my-1 p-3 border-b-2 border-gray-400 outline-none text-black focus:border-primary transition-all' placeholder='Your Message' name='massage' required/>
              <div className='absolute inset-y-0 right-0 flex items-baseline px-2 py-1 text-primary_medium'>
                <FaRegComment/>
              </div>
            </div>

            <div className='relative w-full'>
              <button type="Submit" className='w-full shadow-md hover:bg-primary p-2 my-3 bg-secondary text-sm font-light text-white transition-all'>Send</button>
              <div className='absolute inset-y-0 right-0 flex items-center px-2 text-white'>
               <AiOutlineSend/>
              </div>
              
            </div>
          </form>
        </motion.div>
      </ModalTemplate>
      <ToastContainer/>
    </div>
  )
}

export default Email