import React from 'react'
import {motion} from 'framer-motion'
import Texts from '../../../Values/Texts'

 const CircleTemplateList=({Text,icon})=>{
    return(
      <motion.div className='mt-24 w-full h-full flex md:justify-end justify-center items-center' >
      <motion.div className='rotate-90 md:rotate-0 md:h-60 h-[21rem] w-[27rem]  bg-white md:w-[85%] flex justify-center items-center md:rounded-l-full  my-24 '
      >
        <div className={`md:h-72 md:w-72 w-52 h-52 -ml-24 md:m-0  md:rotate-0 -rotate-90 flex justify-center items-center flex-col border-8 border-primary_lighter bg-primary p-12 rounded-full z-20`}>
          <img src={icon} className='invert w-16 m-5' alt="" />
          <h1 className=' text-center font-bold text-white md:text-3xl text-2xl'>
          {Text}
          </h1>
        </div>
        <l className='list-disc list-outside flex-1 m-2 md:rotate-0 -rotate-90 text-xl font-light leading-snug w-[72rem] text-start'>
        {Texts.Goal.map((goal, index) => (
            <li key={index} >{goal}</li>
          ))}
      </l>
      </motion.div>
    </motion.div>
    )
  }
  export default CircleTemplateList