import React from 'react'
import Texts from '../../Values/Texts'
import CircleTemplate from './HomeComponents/CircleTemplate'
import CircleTemplateList from './HomeComponents/CIrcleTemplateList'
import goal from '../../Assets/women-beauty.png'
import mission from '../../Assets/awarded-lady.png'
import vision from '../../Assets/women-emotions.png'
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer";


const Home = ({element}) => {
  const [ref, inView] = useInView({
    threshold: 1, // trigger when the component is 50% visible
    triggerOnce: true, // only trigger once
  });
  const container={
    hidden:{
      transition: {
        when: "afterChildren",
      },
    },
    visible:{   
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
        duration: 1,
      },
    }
  }
  const text={
    hidden:{
      x:100,
      opacity:0
    },
    visible:{
      x:0,
      opacity:1,
      transition:{
        duration:1
      }
  }
}
const inverttext={
  hidden:{
    x:-100,
    opacity:0
  },
  visible:{
    x:0,
    opacity:1,
    transition:{
      duration:1
    }
}
}
const div={
  hidden:{
    y:-100,
    opacity:0
  },
  visible:{
    y:0,
    opacity:1,
    transition:{
      duration:1
    }
}
}

  return (
    <>
    <motion.div
    ref={ref}
    variants={container}
    initial="hidden"
    animate={inView? 'visible':'visible'}
    >
    <div ref={element} id='Home' className="h-fit flex justify-center items-center bg-homeBackground md:bg-cover md:bg-center bg-contains bg-top bg-no-repeat md:bg-fixed bg-fixed">
        <motion.div className='w-[80%] text-center h-screen flex justify-center items-start flex-col'
          >
          <div className='flex items-center'>
          <motion.div className='w-1 md:h-20 h-14 mr-3 bg-primary'  
          variants={div}/>
          <div>
          <motion.h2 className='text-left max-md:text-4xl max-lg:text-4xl max-sm:text-2xl text-4xl  text-white font-light'
          variants={text}
          >WELCOME TO </motion.h2> 
          <motion.h2 className='text-left max-md:text-4xl max-lg:text-4xl max-sm:text-3xl text-5xl text-primary_medium font-bold'
          variants={text}>MAMA'S JOURNEY</motion.h2>
          </div>
          </div>
          <motion.h1 className='max-md:text-2xl max-lg:text-2xl max-sm:text-sm text-2xl text-white leading-loose text-left font-extralight'
          variants={text}
          >
          {Texts.Intro}
          </motion.h1>
        </motion.div>
    </div> 
  </motion.div >
   <motion.div 
    className='h-full flex justify-center flex-col'
    ref={ref}
    variants={container}
    initial="hidden"
    animate={inView? 'visible':'visible'}>
    
   <motion.div variants={text}><CircleTemplate Text='Mission' icon={mission} body={Texts.Mission}/></motion.div>
   <motion.div variants={inverttext}><CircleTemplate Text='Vision' icon={vision} right={true} body={Texts.Vision}/></motion.div>
   <motion.div variants={text}><CircleTemplateList Text='Goal' icon={goal}  color='secondary' /></motion.div>
   </motion.div>
    </>
  )
}
export default Home