import React, { useState } from 'react'
import { Link } from 'react-scroll'
import {AiFillFacebook} from 'react-icons/ai'
import { AiFillInstagram } from 'react-icons/ai'
import { AiFillLinkedin } from 'react-icons/ai'
import { FaTelegram } from 'react-icons/fa'
import Email from '../Email/Email'
import { motion } from 'framer-motion'


const Footer = () => {

  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <motion.div className='bg-secondary overflow-hidden' >
    <div className='bg-secondary mx-auto flex lg:flex p-8 justify-around lg:justify-between text-white' id='Contact'>
      <div className='px-5'>
      <h1 className='font-bold'>Category</h1>
      <div className='lg:space-x-16 flex flex-col lg:flex-row'>
      <Link
      spy={true}
      smooth={true}
      offset={-100}
      duration={1000} to='Home'
      className='cursor-pointer hover:text-primary  mt-2'
      >Home</Link>
      <Link
      spy={true}
      smooth={true}
      offset={-100}
      duration={1000} to='About'
      className='cursor-pointer hover:text-primary  mt-2'
      >About Us</Link>
      <Link
      spy={true}
      smooth={true}
      offset={-100}
      duration={1000} to='Services'
      className='cursor-pointer hover:text-primary  mt-2'
      >Service</Link>
      <Link
      spy={true}
      smooth={true}
      offset={-100}
      duration={1000} to='Partners' 
      className='cursor-pointer hover:text-primary  mt-2'
      >Partner</Link>
      </div>
      </div>
<div className=' justify-between md:justify-between flex flex-col lg:space-x-72 lg:flex-row'>
      <div className='space-y-2'>
      <h1 className='font-bold'>Join us</h1>
      <div className='space-x-2 flex '>
      <a href="https://www.facebook.com/mammasjourney"><AiFillFacebook className='h-6 w-auto rounded-full hover:text-primary'/></a>
      <a href="https://www.instagram.com/mamasjourney_ethiopia"><AiFillInstagram className='h-6 w-auto rounded-full hover:text-primary'/></a>
      <a href="https://www.linkedin.com/company/mamasjourney"><AiFillLinkedin className='h-6 w-auto rounded-full hover:text-primary'/></a>
      <a href='https://t.me/mammasjourney'><FaTelegram className='h-6 w-auto rounded-full hover:text-primary'/></a>
      </div>
      </div>

      <div>
      <button className='border-white border-2 p-2 rounded-lg hover:bg-primary transition-all' onClick={handleOpen}>Send Message</button>
      <Email isOpen={isOpen} isClosed={handleClose}/>
      </div>
      </div>
    </div>
    <div className='text-center text-white font-light'>
      <div className='flex flex-col sm:flex-row justify-center md:space-x-20'>
      <h1>Powered by Blue Health Ethiopia</h1>
      <h1>Designed by Tilet Tech</h1>
      </div>
      <h2>@Copyright2023</h2>
      </div>
      </motion.div>
  )
}

export default Footer
