import React from 'react'
import Texts from '../../Values/Texts'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';

const Service = () => {
  const [ref, inView] = useInView({
    threshold: 0.5, // trigger when the component is 50% visible
    triggerOnce: true, // only trigger once
  });
  const container={
    hidden:{
      opacity:0,
      y:-100,
      transition: {
        when: "afterChildren",
      },
    },
    visible:{
      y:0,
      opacity:1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
        duration: 1,
      },
    }
  }
  const text={
    hidden:{
      opacity:0,
      scale:0
    },
    visible:{
      opacity:1,
      scale:1,
      transition:{
        duration:1,
        type: "spring",
        stiffness: 50,
        damping: 20
        }
    }
    }
  return (
    <motion.div className='bg-servicebg  md:bg-cover md:bg-center bg-cover bg-top bg-no-repeat text-white text-center p-7 md:p-44 md:h-fit bg-fixed' id='Services'    >
    <motion.div
    ref={ref}
    variants={container}
    initial="hidden"
    animate={inView ? "visible":"hidden"}
    >
      <motion.h1 className='md:text-4xl text-2xl font-bold'
      variants={text}
      >Service</motion.h1>
      <motion.p className='text-center md:text-2xl font-light md:h-fit w-full h-40 p-2 overflow-y-scroll scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-transparent scrollbar-thumb-primary'
      variants={text}
      >{Texts.Services}</motion.p>
      </motion.div>
    </motion.div>
  )
}

export default Service
