const Texts = ({
  Mission:"To empower expectant parents through evidence based education with the knowledge and skills "+
  "they need to feel confident and prepare for the arrival of their baby.",
  Vision:"Empowering expectant parents with knowledge and skills to confidently navigate the journey of pregnancy,"+
  "childbirth and early parenthood to ensure safe and positive birth experiences.",
  Goal:[
    "increase maternal health awareness among expectant mothers in Ethiopia.",
    "ensure a safe pregnancy, childbirth, and postpartum recovery period.",
    "ensure that pregnant women, new mothers, and their families receive adequate support, education, training and guidance.",
  ],
  Services:"At Mama's Journey, we provide expecting parents and their partners with "+
  "comprehensive classes to confidently prepare for their new baby's arrival. "+
  "Our informative seminars, interactive workshops, and educational materials "+ 
  "cover all aspects of pregnancy, childbirth, and newborn care, empowering you to make well-informed decisions. "+
  "Let us help you navigate this transformative time in your life with our expert guidance and resources. "+ 
  "Enroll in our classes today and prepare for the exciting journey ahead!",
  Intro:"Confidently prepare for your new baby's arrival with Mama's Journey's comprehensive classes. "+
  "Let our expert guidance and support empower you during this exciting time. ",
  About:"At Mama's Journey, we are passionate about empowering expecting parents and their partners with the "+
   "knowledge and skills they need to confidently navigate pregnancy, childbirth, and the early stages of parenthood. "+
  "With our comprehensive classes and expert guidance, we strive to provide you with the best possible resources to "+
  "make well-informed decisions and enjoy this exciting journey."
  })
  export default Texts